<template>
  <div class="base-content">
    <form-frame
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit">
      <div slot="content">
        <a-card title="车辆信息">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{span:8}"
            :wrapper-col="{span:14}">
            <!--车牌号、车主姓名、联系电话、注册登记日期、保险到期日期、单位介绍人-->
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="车牌号" prop="carNumber">
                  <a-input v-model="form.carNumber" :maxLength="50" :disabled="isDetail" allowClear></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="车主姓名" prop="ownerName">
                  <a-input v-model="form.ownerName" :maxLength="50"  allowClear></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="联系电话" prop="mobile">
                  <a-input v-model="form.mobile" :maxLength="50"  allowClear></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
<!--            <a-row>-->
<!--              <a-col :span="10">-->
<!--                <a-form-model-item label="有效时间" prop="duration">-->
<!--                  <a-range-picker-->
<!--                    v-model="form.duration"-->
<!--                    class="x-inner"-->
<!--                    format="YYYY-MM-DD"-->
<!--                  ></a-range-picker>-->
<!--                </a-form-model-item>-->
<!--              </a-col>-->
<!--            </a-row>-->
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="注册登记日期" prop="registerTime">
                  <a-date-picker
                    v-model="form.registerTime"
                    type="date"
                    placeholder="请选择注册登记日期"
                    style="width: 100%"
                  >
                  </a-date-picker>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="保险到期日期" prop="insuranceExpirationTime">
                  <a-date-picker
                    v-model="form.insuranceExpirationTime"
                    type="date"
                    placeholder="请选择保险到期日期"
                    style="width: 100%"
                  >
                  </a-date-picker>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="单位介绍人" prop="introducer">
                  <a-input v-model="form.introducer" :maxLength="50"  allowClear></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </div>
    </form-frame>
  </div>
</template>

<script>
import moment from 'moment';
import { carAddOrEdit, queryCarInfoByCarNumber } from '@/api/car';
import FormFrame from '@/components/frame/FormFrame.vue';
import formMixin from '@/mixin/formMixin';

export default {
  name: 'CarForm',
  mixins: [formMixin],
  components: {
    FormFrame,
  },
  data() {
    return {
      form: {
        carNumber: '',
        ownerName: '',
        mobile: '',
        registerTime: null,
        insuranceExpirationTime: null,
        introducer: '',
      },
      rules: {
        carNumber: [
          {
            required: true,
            message: '车牌号不能为空',
            trigger: 'blur',
          },
        ],
        ownerName: [
          {
            required: true,
            message: '车主姓名不能为空',
            trigger: 'blur',
          },
        ],
        mobile: [
          {
            required: true,
            message: '手机号不能为空',
            trigger: 'blur',
          },
        ],
        registerTime: [
          {
            required: true,
            message: '注册日期不能为空',
            trigger: 'blur',
          },
        ],
        insuranceExpirationTime: [
          {
            required: true,
            message: '保险到期日期不能为空',
            trigger: 'blur',
          },
        ],
        // introducer: [
        //   {
        //     required: true,
        //     message: '单位介绍人不能为空',
        //     trigger: 'blur',
        //   },
        // ],
      },
    };
  },
  computed: {
    paramsId() {
      return this.$route.params.id;
    }
  },
  created() {
    if (this.paramsId) {
      this.getData(this.paramsId);
    } else {
      this.form.registerTime = moment(moment(new Date()).format('YYYY-MM-DD'));
      this.form.insuranceExpirationTime = moment(moment(new Date()).format('YYYY-MM-DD'));
    }
  },
  methods: {
    // 获取信息
    getData(id) {
      console.log(id);
      queryCarInfoByCarNumber(id).then((data) => {
        console.log(data);
        if (data) {
          data.registerTime = moment(data.registerTime);
          data.insuranceExpirationTime = moment(data.insuranceExpirationTime);
          this.form = data;
        }
      });
    },

    // 提交
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
          };
          params.registerTime = moment(this.form.registerTime).format('YYYY-MM-DD 23:59:59');
          params.insuranceExpirationTime = moment(this.form.insuranceExpirationTime).format('YYYY-MM-DD 23:59:59');
          console.log(params);
          carAddOrEdit(params).then((res) => {
            if (res) {
              this.$message.success(res.message || '操作成功');
              this.handleBack();
            } else {
              this.$message.error('操作失败');
              return false;
            }
          }).catch((res) => {
            console.log(res);
          });
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
