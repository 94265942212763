import axios from '../libs/axios';
// 车辆信息列表
const carList = (data) => {
  return axios.get('/api/trd/car/list', data);
};

// 新增/修改
const carAddOrEdit = (data) => {
  return axios.post('/api/trd/car/save', data);
};

// 信息
const queryCarInfoByCarNumber = (carNumber) => {
  return axios.post(`/api/trd/car/info/${carNumber}`);
};

// 删除
const carDelete = (data) => {
  return axios.post('/api/trd/car/delete', data);
};



export {
  carList,
  queryCarInfoByCarNumber,
  carAddOrEdit,
  carDelete,
};
